.order-details-page {
  .cart-item-table {
    tbody {
      td {
        &.cart-item__cell--price,
        &.cart-item__cell--total {
          white-space: nowrap;
        }
      }
    }
  }
}

.utility-nav__account {
  @media #{$medium-up} {
    left: -20px;
  }
  @media #{$xlarge-up} {
    left: -10px;
  }
}

.brand-renutriv {
  #content {
    .spp-product__details {
      .spp-product__details {
        &-description,
        &-attribute {
          font-family: $roboto-condensed, serif !important;
          p {
            font-family: $roboto-condensed, serif !important;
          }
        }
        &-attribute {
          &__label {
            font-family: $roboto-condensed, serif !important;
          }
        }
      }
      ul {
        li {
          font-family: $roboto-condensed, serif !important;
        }
      }
    }
  }
  .page-wrapper {
    #content {
      &.main-content {
        a {
          font-family: $roboto-condensed, serif !important;
        }
      }
    }
  }
  .page-main {
    p {
      font-family: $roboto-condensed, serif;
    }
  }
}

// Logo phone
.page-branding .trust-mark-elc-nodeblock {
  @media #{$small-only} {
    display: none;
  }
}

// Gnav overwrite base positions that are based on $header-collapsed-height
// since the height changed on NAV Refresh
.page-navigation,
.page-header .page-navigation.active-menu-toogle,
.page-navigation-second .page-navigation {
  @media #{$xlarge-up} {
    margin-top: 22px;
  }
}

.page-wrapper {
  padding-top: 104px; // increase the base padding top to fit the new nav position
  // right after mobile
  @media #{$medium-up} {
    padding-top: 104px;
  }
  .basic_infinite_carouselOuter.basic_infinite_carousel_header,
  .hero-tout-wrapper,
  .move-up-into-nav,
  .spp:not(.button) {
    margin-top: -104px;
  }
  .mpp_module_wrapper {
    margin: -104px auto 30px;
  }
  .slick-prodpage {
    @media #{$medium-up} {
      margin: -104px auto 30px;
    }
  }
  .checkout {
    @media #{$medium-up} {
      margin-top: -104px;
      padding-top: 104px;
    }
  }
  .cart-confirm {
    &__shade-button {
      font-family: $akzidenz;
    }
  }
}

.checkout-co-enabled {
  &#viewcart {
    .page-wrapper {
      .checkout {
        &__content {
          .samples-panel {
            &__content {
              .product {
                .sample-select-button {
                  padding: 0 2px;
                }
              }
            }
          }
        }
        .checkout {
          &__sidebar {
            .offer-code-panel {
              &__title {
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }
  }
  &#signin {
    .page-wrapper {
      .checkout {
        &__content {
          &.sign-in-page {
            .sign-in-panel {
              position: relative;
              top: 40px;
              @media #{$medium-up} {
                padding-top: 20px;
              }
              .info-link-gdpr {
                text-align: #{$ldirection};
              }
            }
          }
        }
      }
    }
  }
}

.sign-in-component {
  &__form {
    &-loyalty {
      display: none;
    }
  }
  &__confirm {
    &-registration-field-wrapper & {
      &__birthday-program {
        display: none;
      }
    }
    &-options,
    &-registration-fields {
      display: none;
    }
  }
  &__confirm & {
    &__header {
      margin: 35% 5%;
    }
  }
}

.refinement {
  &-mauve_a_modr_ {
    background: $color-shade-mauveblue;
    border-#{$rdirection}: 8px solid $color-shade-bluemauve;
  }
  &-nude_a_hn_d_ {
    background: $color-shade-nudebrown;
    border-#{$rdirection}: 8px solid $color-shade-brownnude;
  }
  &-r_ov_a_ostru_inov_ {
    background: $color-shade-pinkberry;
    border-#{$rdirection}: 8px solid $color-shade-berrypink;
  }
  &-kor_lov_a_erven_ {
    background: $color-shade-coralred;
    border-#{$rdirection}: 8px solid $color-shade-redcoral;
  }
  &-mauve_a_vestkov_ {
    background: $color-shade-mauveplum;
    border-#{$rdirection}: 8px solid $color-shade-plummauve;
  }
}

.skin-finder-results {
  &__skin-diagrams {
    .content-formatter {
      &__line {
        p {
          font-family: $optimaregular;
        }
      }
    }
  }
}

.utility-nav {
  &__signin-text {
    font-family: $akzidenz;
  }
}

.product-full {
  font: 14px $akzidenz;
  &__detail-link,
  &__share,
  &__wishlist,
  .free_standard_shipping_returns {
    font-family: $akzidenz;
  }
}

.viewcart-buttons,
.checkout-page-title {
  font-family: $akzidenz;
}

.checkout {
  .continue-checkout {
    font-family: $akzidenz;
  }
  &__sidebar {
    .price {
      font-family: $akzidenz;
    }
  }
}

.search-page {
  &__headline {
    font-family: $akzidenz;
  }
}
