#bt_settings {
  div {
    &.bt-content {
      .bt-intro {
        h2 {
          font-size: 30px;
          @media (max-device-width: 767px) {
            font-size: 20px;
          }
        }
        li {
          font-size: 15px;
          margin: 8px auto;
        }
      }
      ul {
        &.slide-toggle {
          background: transparent url('/media/images/cookies/all-off_cz.png');
          @media #{$small-only} {
            background: transparent url('/media/images/cookies/m_all-off_cz.png');
            width: 246px;
            padding-left: 0;
            background-size: 246px 147px;
          }
          li {
            @media (max-device-width: 767px) {
              width: 82px;
              height: 147px;
            }
          }
        }
      }
      div {
        &.slide {
          @media (max-device-width: 767px) {
            width: 246px;
          }
        }
        .mask {
          ul {
            &.slide-toggle {
              background: transparent url('/media/images/cookies/all-on_cz.png');
              @media #{$small-only} {
                background: transparent url('/media/images/cookies/m_all-on_cz.png');
                width: 246px;
                padding-left: 0;
                background-size: 246px 147px;
              }
            }
          }
          div {
            &.drag {
              bottom: 23px;
              @media #{$small-only} {
                right: 30px;
              }
            }
          }
        }
      }
      .bt-privacy-info {
        div {
          @media (max-device-width: 767px) {
            width: 100%;
            float: none;
          }
        }
        h3 {
          padding: 1em 1em 1em 40px;
          background-position: 10px 50%;
          background-repeat: no-repeat;
        }
        .bt-privacy-will {
          li {
            list-style-type: none;
            font-size: 15px;
          }
          h3 {
            font-size: 25px;
            @media (max-device-width: 767px) {
              font-size: 18px;
            }
          }
        }
        .bt-privacy-willnot {
          li {
            list-style-type: none;
            font-size: 15px;
          }
          h3 {
            font-size: 25px;
            @media (max-device-width: 767px) {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

.tealium {
  display: none !important;
}

#__tealium_tagO2 {
  display: none;
}
