.enhanced_delivery_page {
  .shipping {
    #content {
      .shipping-panel {
        .address-container {
          margin: 0;
        }
      }
    }
  }
  .page-wrapper {
    .pg_wrapper {
      .checkout_container {
        .checkout__content {
          #address-section-container {
            .shipping-address-form-container {
              .first_name_container {
                input[type='text'].adaptive-label-mode {
                  & + label::before {
                    transform: translateY(-33px) scale(0.8, 0.8);
                  }
                }
              }
            }
            .address_controls {
              #choose-address {
                .add-new-address {
                  .add-address {
                    &::after,
                    &::before {
                      top: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .payment-panel {
        #payment-other-terms {
          input[type='checkbox'] {
            & ~ label {
              &::before {
                top: 1px;
              }
              &::after {
                top: 3px;
              }
            }
          }
        }
        #payment-type-cc {
          .payment-type {
            border-top: 1px solid $color-light-gray;
          }
        }
      }
    }
  }
  .page-header {
    padding: 0;
  }
  .pg_wrapper {
    .checkout_container {
      .checkout__content {
        #address-section-container {
          #billing_address_form_container {
            .address4_containe {
              display: none;
            }
          }
          .billing-panel {
            .billing-address-form-container {
              .country_container {
                background: $color-azure-transparent;
                border: 0;
                font-size: 12px;
                height: 54px;
                line-height: 1.34;
                margin-bottom: 22px;
                padding: 0;
              }
              textarea.field + label {
                font-size: 12px;
                line-height: 3;
              }
              .delivery_instructions,
              .google_autocomplete,
              .phone_2_container,
              .instructions.phone_label_container,
              .country_container label {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
