@font-face {
  font-family: 'Conv_OptimDisLCE_0';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimDisLCE_0.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
h4 {
  font-style: normal;
  font-weight: normal;
  font-family: 'Conv_OptimDisLCE_0';
}

.product-full__title {
  font-family: 'Conv_OptimDisLCE_0';
}

.special-offer-row-promo-wrapper.special-offer-theme-regular {
  .special-offer-headline {
    font-family: 'Conv_OptimDisLCE_0' !important;
  }
}

.brand-renutriv {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $bb-roman !important;
  }
  #colorbox {
    &.colorbox__quickshop {
      #cboxContent {
        background: #000;
      }
      .quickshop {
        font-family: $roboto-condensed, serif;
      }
    }
  }
}

.brand-aerin {
  .page-main {
    font-family: $akzidenz !important;
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .selectBox-label,
    .product_brief__price,
    .product-brief__view-details,
    .text--short,
    label,
    .cart-item__qty,
    .headline--tertiary {
      font-family: $akzidenz !important;
    }
  }
}

.multi_use_homepage_v1 {
  .module_block_Aerin {
    .headline_content__subcopy {
      color: $color-periwinkle-blue--aerin;
    }
    .headline_content__subcopy,
    .button.cta.cta__button.button_style_dark {
      font-family: $akzidenz !important;
    }
  }
}

.woa-blog__content {
  .ee-disco-more__label {
    font-family: 'Futura Now Medium' !important;
  }
}

#search-help {
  .search-help__content {
    .help-number {
      display: block;
    }
  }
}

.page-branding__logo {
  float: right;
}

.trust-mark-elc-nodeblock {
  float: left;
  .trust_mark_header {
    position: relative;
    width: 80px;
    height: 80px;
    .trust_mark_header__link {
      position: absolute;
      width: 70px;
      height: 80px;
      top: -22px;
      background-image: url('/media/images/global/lo3_cz.png');
      background-repeat: no-repeat;
      text-indent: -9999px;
    }
  }
}

.page-utilities-elc-nodeblock {
  float: left;
}

.is-alternate,
.brand-renutriv,
#beauty_feed {
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        background-image: url('/media/images/global/lo3_white_cz.png');
      }
    }
  }
}

.ff-quiz {
  .ff-quiz__footer {
    .ff-quiz__link--help {
      display: none;
    }
  }
}

.beauty-chat-tray__main-copy {
  .lpPoweredByDiv {
    display: none;
  }
}

.section-store-locator {
  .local-search__map {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

body {
  &.ie-8 {
    header.page-header {
      nav.page-navigation {
        width: 63%;
      }
    }
  }
  .info-msg-gdpr {
    width: auto;
    max-width: 700px;
    max-height: 30%;
    overflow-y: auto;
  }
}

.mpp-compare__container {
  .mpp-compare-vba {
    display: none;
  }
}

#engraving_block {
  display: none;
}

.spp {
  .product__inventory-status {
    li.temp-out-of-stock {
      width: 193px;
      a.cta {
        margin: 6px 0 0 0 !important;
      }
    }
  }
  .product-full__description {
    .product-full__description-section {
      .product-full__attribute {
        float: none;
      }
    }
  }
}

.colorbox__wishlist-confirm {
  #cboxClose {
    top: 5px;
    right: 5px !important;
  }
  .wishlist-confirm__container {
    .wishlist-confirm__message {
      margin: 6px 20px 0 20px;
      font-family: 'Conv_OptimDisLCE_0';
    }
  }
  #cboxLoadedContent {
    height: 130px !important;
  }
}

#cboxLoadedContent {
  margin-top: 30px;
}

.page-sticky-footer {
  .page-sticky-footer__left {
    .node-elc-nodeblock {
      border-left: none;
    }
  }
}

.product-full__price-size {
  .product__inventory-status {
    height: 28px !important;
    margin-left: 0 !important;
  }
}

#waitlist-overlay {
  height: auto;
  p {
    line-height: 18px;
    margin: 10px 0;
  }
  #waitlist-form {
    .form-item {
      margin-bottom: 10px;
    }
  }
}

.appt-book {
  .appt-book-location-selector {
    .location-submit {
      max-width: 185px !important;
    }
  }
  #confirmation {
    .confirmation-container {
      .artist {
        display: none;
      }
    }
  }
  .services-container {
    margin-bottom: 0 !important;
  }
  .services-skincare {
    padding: 0 !important;
    margin: 0 !important;
    .services-skincare-header {
      margin-bottom: 20px;
    }
    .services-skincare-content {
      .service {
        margin-bottom: 20px;
      }
    }
  }
  .service-select {
    .service {
      .service-details {
        h3.service-title {
          line-height: normal !important;
        }
      }
    }
  }
  .book-appt-container {
    input {
      color: black !important;
    }
    .mobile-phone-fieldset {
      float: right;
      input.appt-book-mobile-prefix {
        width: 20%;
        border-right: none;
      }
      input.appt-book-mobile {
        width: 80%;
        float: right;
        margin-bottom: 15px;
      }
    }
  }
  @media #{$medium-down} {
    .services-renutrive {
      .service {
        .service-details {
          h3.service-title {
            height: 126px;
          }
          .lesson_description {
            height: 75px;
          }
        }
      }
    }
  }
}

.store-locator {
  .local-search-form__banner {
    margin: 20px 0 0;
    text-align: center;
    img {
      width: 100%;
    }
  }
  .door-row__buttons.is_booking {
    div:first-child {
      margin-right: 5%;
    }
    div {
      width: 46.6%;
      display: inline-block;
    }
    .door-row__booking {
      .store-locator_book-appointment {
        line-height: 20px;
        padding: 0;
      }
    }
  }
  .location-info__booking {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #000;
    padding: 5px 10px;
    .store-locator_book-appointment {
      font-size: 10px;
      font-weight: bold;
      .icon {
        background: transparent
          url('/media/export/cms/appointment_booking/icons/icon-cal-locator.gif') no-repeat scroll 0
          0 / 100% auto;
        cursor: pointer;
        height: 19px;
        width: 25px;
        background-size: contain;
        font-size: 15px;
        padding-right: 10px;
      }
    }
  }
}

.page-navigation {
  @media (min-width: 737px) and (max-width: 768px) {
    margin-top: 38px;
  }
  .menu-item-container {
    margin-left: -30px !important;
  }
  &__menu {
    h3 {
      &.menu-ref {
        &__title {
          font-family: $akzidenz;
        }
      }
    }
    .menu-ref {
      .menu {
        &__item {
          &--category {
            font-family: $akzidenz;
          }
        }
      }
    }
  }
}

.main-inner {
  .spp {
    .spp__reviews_head {
      display: none;
    }
  }
}

.page-footer {
  .page-sticky-footer {
    .special-offers-tray {
      .offers-email-signup-form {
        display: none;
      }
    }
  }
}

button.js-add-to-cart.product-full__add-button.button.cta {
  margin-left: 10px;
  float: left;
  margin-bottom: 1.5%;
}

.page-utilities__cart {
  min-width: 65px;
}

.brand-renutriv,
.page-product,
.product-full,
.quickshop,
.spp-product {
  .quickshop-inline {
    ul.shade-list {
      margin: 16px auto 0 0;
    }
  }
  ul.shade-list {
    margin: 16px auto 0 0;
  }
}

.footer-legal {
  @media #{$medium-up} {
    height: auto;
  }
  .field-menu {
    .menu {
      overflow: auto;
    }
  }
}

.footer-forms-location-sms-email-signup-elc-nodeblock {
  .footer-signup-email {
    @media #{$large-up} {
      width: 30%;
    }
    .email_signup__terms {
      float: left;
      .label::before,
      .label::after {
        top: 7px;
      }
    }
  }
}

.footer-need-help {
  .call-us {
    padding: 0;
    padding-#{$ldirection}: 25px;
  }
}

.welcome15-overlay.alt_layout1 {
  .email_popover {
    .label::after {
      background-image: url('/media/images/background-images/background-image12.png');
      @include swap_direction(border, solid 1px $color-light-gray);
    }
  }
}

#review-instructions {
  margin-bottom: 15px;
}

.responsive-footer {
  .depth-1 {
    &.menu-container {
      @media #{$xlarge-up} {
        padding-top: 0;
      }
    }
  }
}
// 2018 Creative refresh
.header-items {
  @media #{$xlarge-up} {
    padding: 9px 0 0;
  }
}

.gnav-active,
.mobile-gnav-active {
  #{$alt},
  .brand-renutriv,
  .section-re-nutriv,
  #beauty_feed {
    .trust-mark-elc-nodeblock {
      .trust_mark_header {
        .trust_mark_header__link {
          background-image: url('/media/images/global/lo3_cz.png');
        }
      }
    }
  }
}

.account-body {
  .order-status-table {
    .order-status-table {
      &__row {
        &--header {
          display: table-row;
        }
      }
    }
  }
}

.registration-page {
  &__personal-info {
    .form-item {
      margin-bottom: 10px;
    }
  }
}

.address-book-info {
  .address_display {
    .controls {
      .billing-address {
        border: none;
      }
    }
  }
}

.shipping-country-info-text {
  clear: both;
}

#colorbox {
  &.signin-overlay-wrapper {
    $margin: 50px;
    $width: calc(520px + #{$margin * 2});
    $height: calc(505px + #{$margin * 2});
    width: #{$width} !important;
    height: #{$height} !important;
    min-width: #{$width} !important;
    min-height: #{$height} !important;
    top: calc((100% - #{$height}) / 2) !important;
    left: calc((100% - #{$width}) / 2) !important;
    #cboxWrapper {
      width: 100% !important;
      height: 100% !important;
      > div:nth-child(2) {
        width: 100% !important;
        height: 100% !important;
        #cboxContent {
          width: 100% !important;
          height: 100% !important;
          padding: 0 !important;
          #cboxLoadedContent {
            width: 100% !important;
            height: 100% !important;
            margin: 0 !important;
            overflow: hidden !important;
            .sign-in-component {
              &.registration,
              &.registration-confirmation {
                width: 100%;
              }
              &__form {
                margin-top: #{$margin};
                margin-left: #{$margin * 4};
              }
              &__confirm {
                margin: 0;
                .sign-in-component {
                  &__header {
                    margin: 40% 0 0 20%;
                  }
                }
              }
              &__registration-confirmation-header {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.info-msg-gdpr {
  .bottom-left-arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $color-periwinkle-blue;
    bottom: -11px;
    position: absolute;
  }
}

.el-search-block {
  input[type='text'] {
    &.form-text {
      font-family: Conv_Optim Dis LCE_0;
    }
  }
}

.page-wrapper {
  .account {
    .sign-in-component {
      & > .error_messages {
        text-align: #{$ldirection};
        width: 43%;
        margin: 20px auto;
        padding: 0;
      }
    }
  }
}
